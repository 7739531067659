<template>
  <div>
  <CCard>
   
  <CCardBody>
    <!-- <div class="clearfix">
      <button type="button" class="btn btn-secondary float-xl-right" @click="navToNewSymptoms">New </button>
    </div> -->

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination>
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #startDate="{item}">
        <td class='font-weight-bold'
          v-if="item.startDate"
        >{{ item.startDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ")  }}</td>
        <td v-else>--</td>
      </template>
      <template #endDate="{item}">
        <td class='font-weight-bold'
          v-if="item.endDate"
        >{{ item.endDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>
       <template #startTime="{ item }">
          <td class='font-weight-bold' v-if="item.startTime">
            {{ item.startTime | dateParse("HH.mm") | dateFormat("hh:mm A ") }}
          </td>
          <td v-else>--</td>
        </template>
       <template #endTime="{ item }">
          <td class='font-weight-bold' v-if="item.endTime">
            {{ item.endTime | dateParse("HH.mm") | dateFormat("hh:mm A ") }}
          </td>
          <td v-else>--</td>
        </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  
  { key: "startDate",label: "Start Date", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "startTime",label: "Start Time", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "endDate",label: "End Date", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  { key: "endTime",label: "End Time", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  
 

  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "JobOT",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getJobOts() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/job/ot/"+this.$route.params.jobId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    //  movetrash(faqId) {
    //  this.apiGetSecure(process.env.VUE_APP_API_HOST + "/web/secure/delete/faq/" + faqId, {
    //     method: "GET",
    //     // headers: this.defaultHeaders()
    //   });
    //  this.getSymptoms();
    //   this.$nextTick(() => {
    //     this.$refs.vuetable.refresh();
    //   });
    //   window.location.reload();
    // },
    // deleteFaq(groupId) {
    //   console.log("delete called with", this.groupId);
    //  fetch(
    //     process.env.VUE_APP_API_HOST +
    //       "/emapp/web/secure/delete/group/" +
    //      groupId
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getDepartments();
    //          this.$nextTick(() => {
    //          this.$refs.vuetable.refresh();
    //        });
    //        window.location.reload();
    //     });
    // },
    // navToNewSymptoms(){
    //    this.$router.push({ name: "Group", params: { mode: "new" } });
    // }
  },
  mounted() {
    this.getJobOts();
  },
};
</script>